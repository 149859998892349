import { Box } from '@mui/material';
import React from 'react';
import { DARK, GREY } from 'theme/palette/new';

type Props = {
  isReadOnly?: boolean;
  label?: string | number;
  customBackGroundColor?: string;
};

export const GeneralBadge: React.FC<Props> = ({
  isReadOnly = false,
  label,
  customBackGroundColor,
}: Props) => {
  return (
    <Box
      sx={{
        padding: '4px 8px',
        border: isReadOnly ? `1px solid ${GREY[200]}` : 'none',
        borderRadius: '5px',
        color: isReadOnly ? `${GREY[400]}` : DARK[200],
        fontSize: '12px',
        fontWeight: 400,
        backgroundColor: customBackGroundColor ? customBackGroundColor : 'inherit',
      }}
    >
      {label ? label : ''}
    </Box>
  );
};
