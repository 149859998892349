import { Box } from '@mui/material';
import { MultiSelectChip } from 'components/fields/MultiSelectInfinite/multiSelect';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
interface SelectedItem {
  id: number;
  label: string;
}
export const Playground = () => {
  const [data, setData] = useState<SelectedItem[]>([]);
  const methods = useForm();
  const itemsSelected = Array.from({ length: 17 }, (_, i) => ({
    id: i + 1,
  }));
  const items = Array.from({ length: 17 }, (_, i) => ({
    id: i + 1,
    label: `itemsssssssssssss${i + 1}`,
  }));
  useEffect(() => {
    setTimeout(() => {
      setData(items);
    }, 1000);
    methods.setValue('a', itemsSelected);
  }, [data.length]);
  return (
    <Box p={4}>
      <FormProvider {...methods}>
        <MultiSelectChip
          caption='Risk Factors'
          fieldName='a'
          placeholder='Select Category...'
          data={data}
          alt='Read Only'
          isReadOnly
        />
      </FormProvider>
    </Box>
  );
};
