import { GREY } from 'theme/palette/new';

export const generateAllOptions = (
  data: any,
  selectAllText: string,
  fisrtCustomizedOptionEnabled?: boolean,
) => {
  if (fisrtCustomizedOptionEnabled) {
    return [{ id: 'customOption', label: selectAllText || 'Select All' }, ...data];
  }
  return data;
};

export const getSelectedValues = (allOptions, value) => {
  const dataIds = (value || []).map((el) => el.id);
  return allOptions.filter((el) => dataIds.includes(el.id));
};

export const getOptionStyles = (optionId: string, isCustomOptionSelected: boolean) => {
  if (optionId === 'customOption') {
    return { borderBottom: `1px solid ${GREY[200]}` };
  }
  if (isCustomOptionSelected && optionId !== 'customOption') {
    return { opacity: 0.5, pointerEvents: 'none' as const };
  }
  return {};
};
