import { ErrorMessage } from '@hookform/error-message';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Box,
  Checkbox,
  createFilterOptions,
  TextField,
  Typography,
} from '@mui/material';
import ErrorSpan from 'components/ErrorSpan';
import { ViewMoreContainer } from 'components/ViewMoreContainer';
import WipChip from 'components/WipChip';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { dark } from 'theme/palette';
import { autoCompleteMultiStyles, errorStyle } from 'theme/styles/inputs';
import {
  generateAllOptions,
  getOptionStyles,
  getSelectedValues,
} from 'utils/components/multiSelectChip';
import { optionType, optionTypeWithSubItems } from 'utils/data-to-options';
const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: any) => option.label,
  ignoreCase: true,
  ignoreAccents: true,
});
const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;
type DropDownProps = {
  caption: string;
  fieldName: string;
  data: optionType[] | optionTypeWithSubItems[];
  placeholder?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  customArrayErrors?: boolean;
  justifyContent?: 'space-between' | 'space-around' | 'flex-start' | 'flex-end' | 'center';
  alt?: string;
  isWip?: boolean;
  noCaption?: boolean;
  selectAllText?: string;
  fisrtCustomizedOptionEnabled?: boolean;
};

export const MultiSelectChip: React.FC<DropDownProps> = (props: DropDownProps) => {
  const {
    caption,
    fieldName,
    data,
    placeholder,
    isDisabled,
    customArrayErrors,
    justifyContent = 'space-between',
    alt,
    isWip,
    noCaption,
    isReadOnly,
    selectAllText = 'Select All',
    fisrtCustomizedOptionEnabled,
  } = props;

  const [isCustomOptionSelected, setIsCustomOptionSelected] = useState(false);

  const { control, formState, resetField } = useFormContext();
  const { errors } = formState;

  const clearSelection = () => {
    if (isDisabled) return;
    if (isCustomOptionSelected) {
      setIsCustomOptionSelected(false);
    }
    resetField(fieldName);
  };

  const handleSelectionChange = (
    newValue,
    isCustomOptionSelected,
    setIsCustomOptionSelected,
    onChange,
    selectAllText,
  ) => {
    if (newValue.some((item) => item.id === 'customOption')) {
      if (isCustomOptionSelected) {
        setIsCustomOptionSelected(false);
        onChange([]);
      } else {
        setIsCustomOptionSelected(true);
        onChange([{ id: 'customOption', label: selectAllText }]);
      }
    } else {
      onChange(newValue);
      setIsCustomOptionSelected(false);
    }
  };

  return (
    <Box display='flex' flexDirection='column' justifyContent={justifyContent}>
      {!noCaption && (
        <Typography
          component='p'
          display='block'
          textAlign='left'
          color={dark[200]}
          variant='body2'
          mb={1}
        >
          {`${caption} `}
          {customArrayErrors ? (
            <ErrorMessage
              errors={errors}
              name={fieldName}
              render={({ message }) => <small style={errorStyle}>{message}</small>}
            />
          ) : (
            <ErrorSpan errors={errors} name={fieldName} />
          )}

          {!!alt && (
            <Typography
              component='span'
              color={dark[200]}
              fontWeight={300}
              fontSize={12}
              sx={{ opacity: 0.5 }}
              ml={0.5}
            >
              {`(${alt})`}
            </Typography>
          )}
          {isWip && <WipChip />}
        </Typography>
      )}
      <Controller
        name={fieldName}
        control={control}
        render={({ field: { onChange, value, ref } }) => {
          const allOptions = generateAllOptions(data, selectAllText, fisrtCustomizedOptionEnabled);
          const selectedValues = getSelectedValues(allOptions, value);
          return !isReadOnly ? (
            <Autocomplete
              disabled={isDisabled}
              multiple
              value={selectedValues}
              onChange={(_event, newValue) =>
                handleSelectionChange(
                  newValue,
                  isCustomOptionSelected,
                  setIsCustomOptionSelected,
                  onChange,
                  selectAllText,
                )
              }
              options={allOptions}
              disableCloseOnSelect
              disableClearable
              getOptionLabel={(item) => item.label}
              renderTags={(items) => {
                const itemCount = items.length;
                const counter = itemCount < 10 ? `0${itemCount}` : itemCount;
                return (
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    width='53px'
                    height='24px'
                    borderRadius={100}
                    bgcolor='#648099'
                    fontSize='16px'
                    color='white'
                    sx={{ cursor: 'pointer' }}
                    onClick={clearSelection}
                  >
                    {counter}
                    <ClearIcon sx={{ fontSize: 16, ml: '3px' }} />
                  </Box>
                );
              }}
              renderInput={(params) => (
                <Box display='flex' flexDirection='row' position='relative'>
                  <SearchIcon className='multisearch' sx={{ color: '#454344' }} />
                  <TextField {...params} placeholder={placeholder} />
                </Box>
              )}
              sx={autoCompleteMultiStyles}
              filterOptions={filterOptions}
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  key={option.id}
                  style={getOptionStyles(option.id, isCustomOptionSelected)}
                >
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={option.id === 'customOption' ? isCustomOptionSelected : selected}
                    disabled={isCustomOptionSelected && option.id !== 'customOption'}
                  />
                  {option.label}
                </li>
              )}
              ref={ref}
            />
          ) : (
            <ViewMoreContainer selectedValues={selectedValues} />
          );
        }}
      />
    </Box>
  );
};
