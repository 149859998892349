import { Box, Button } from '@mui/material';
import { GeneralBadge } from 'components/Badges';
import { Paragraph } from 'components/Text';
import { FontWeight } from 'components/Text/BaseText';
import { useEffect, useRef, useState } from 'react';
import { GREY, WHITE } from 'theme/palette/new';
import { optionType } from 'utils/data-to-options';

type Props = {
  selectedValues: optionType[];
};

export const ViewMoreContainer: React.FC<Props> = ({ selectedValues }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showToggleButton, setShowToggleButton] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);

  const container = containerRef.current;
  const innerContent = innerRef.current;
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (container && innerContent) {
      const innerHeight = innerContent.offsetHeight;
      if (innerHeight > 120) {
        setShowToggleButton(true);
      } else {
        setShowToggleButton(false);
      }
    }
  }, [selectedValues.length, innerContent]);

  return (
    <Box
      ref={containerRef}
      sx={{
        border: `1px solid ${GREY[400]}`,
        borderRadius: '10px',
        padding: '12px 16px',
        maxHeight: isExpanded ? 'none' : '120px',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        alignItems: selectedValues.length === 0 ? 'center' : 'flex-start',
        justifyContent: selectedValues.length === 0 ? 'center' : 'flex-start',
        textAlign: 'center',
      }}
    >
      {selectedValues.length === 0 ? (
        <Box sx={{ marginTop: 4, marginBottom: 4 }}>
          <Paragraph color={GREY[600]} fontSize='20px' fontWeight={FontWeight.bold}>
            Currently there’s no information to display here.
          </Paragraph>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '12px',
              paddingBottom: 4,
            }}
            ref={innerRef}
          >
            {selectedValues.map((item, id) => (
              <GeneralBadge key={id} label={item.label} isReadOnly />
            ))}
          </Box>
          {showToggleButton && (
            <Box
              onClick={handleToggle}
              sx={{
                display: 'flex',
                cursor: 'pointer',
                width: '100%',
                padding: '0px 0px 10px',
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: WHITE[100],
              }}
            >
              <Button variant='tertiarybtnSmall'>{isExpanded ? 'View less' : 'View more'}</Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
